exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-old-landing-page-tsx": () => import("./../../../src/pages/old-landing-page.tsx" /* webpackChunkName: "component---src-pages-old-landing-page-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-selection-tsx": () => import("./../../../src/pages/product-selection.tsx" /* webpackChunkName: "component---src-pages-product-selection-tsx" */),
  "component---src-pages-purchase-success-tsx": () => import("./../../../src/pages/purchase-success.tsx" /* webpackChunkName: "component---src-pages-purchase-success-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refund-policy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-shipping-policy-tsx": () => import("./../../../src/pages/shipping-policy.tsx" /* webpackChunkName: "component---src-pages-shipping-policy-tsx" */),
  "component---src-pages-sleep-and-burn-tsx": () => import("./../../../src/pages/sleep-and-burn.tsx" /* webpackChunkName: "component---src-pages-sleep-and-burn-tsx" */),
  "component---src-pages-summary-tsx": () => import("./../../../src/pages/summary.tsx" /* webpackChunkName: "component---src-pages-summary-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-weight-loss-index-tsx": () => import("./../../../src/pages/weight-loss/index.tsx" /* webpackChunkName: "component---src-pages-weight-loss-index-tsx" */),
  "component---src-pages-weight-loss-quiz-tsx": () => import("./../../../src/pages/weight-loss/quiz.tsx" /* webpackChunkName: "component---src-pages-weight-loss-quiz-tsx" */),
  "component---src-pages-weight-loss-testimonial-landing-page-tsx": () => import("./../../../src/pages/weight-loss/testimonial-landing-page.tsx" /* webpackChunkName: "component---src-pages-weight-loss-testimonial-landing-page-tsx" */)
}

